<template>
    <div class="addresses-page">
        <div class="container-fluid">
            <h1>Adresy</h1>
            <div class="d-flex justify-content-between mt-3">
                <b-pagination v-if="totalRows>perPage" 
                    v-model="currentPage"
                    :totalRows="totalRows"
                    :per-page="perPage"
                ></b-pagination>
            </div>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <b-table v-else :items="myDataProvider" :fields="fields" :filter="filter" :show-empty="true"
                    class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych"
                    :current-page="currentPage" :per-page="perPage"
                    sort-by="id"
                    :sort-desc="true"
                >
            </b-table>
            
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'adminAddresses',
    data() {
        return {
            isLoading: false,
            filter: [],
            perPage: 25,
            currentPage: 1,
            totalRows: 0,
            fields: [
                { label: 'Id', key: 'id', sortable: true },
                { label: 'Klient', key: 'user_name', sortable: true },
                { label: 'Trasa', key: 'route_name', sortable: true },
                { label: 'Ulica', key: 'street', sortable: true },
                { label: 'Numer domu', key: 'number', sortable: true },
                { label: 'Kod pocztowy', key: 'postcode', sortable: true },
                { label: 'Miasto', key: 'city', sortable: true },
                { label: 'Komentarz', key: 'comment', sortable: true },
                { label: 'Telefon', key: 'phone', sortable: true },
                { label: 'geo lat', key: 'lat', sortable: true },
                { label: 'geo long', key: 'long', sortable: true },
                { label: 'Usunięty', key: 'deleted_at', sortable: true }
            ]
        }
    },
    computed: {
    },
    methods: {
        myDataProvider(ctx) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            const promise = axios.post('/admin/addresses', {
                "perPage": ctx.perPage,
                "currentPage": ctx.currentPage,
                "sortBy": ctx.sortBy,
                "asc": ctx.sortDesc ? false : true,
                "filter": []
            });

            return promise.then(result => {
                const items = result.data.addresses;
                this.totalRows = result.data.count || 0;
                return items || []
            })
        }
    }
}
</script>
